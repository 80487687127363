<template>
    <div>
        <router-link class="client-card card shadow-sm text-decoration-none text-dark rounded-lg h-100"
                     v-bind:class="{ 'is-selected': isSelected, 'shadow-lg': isSelected, }"
                     v-bind:to="`/clients/${ id }`">
            <div class="card-body d-flex justify-content-between py-2">
                <div class="flex-grow-1">
                    <h2 class="font-weight-bolder card-title mb-0">
                        {{ name }}
                    </h2>
                    <p>
                        {{ email }}
                    </p>
                </div>
                <div class="">
                    <p class="text-nowrap text-right"
                       v-if="badges.length === 0">
                        No badge
                    </p>
                    <h5 class="font-weight-normal text-right mb-0">
                        <span class="badge badge-primary mr-1"
                              v-for="{ id, name, } in badges.slice(0, 3)"
                              v-bind:key="id">
                            {{ name }}
                        </span>
                    </h5>
                    <p class="text-right mb-0"
                       v-if="badges.length > 3">
                        and {{ badges.length - 3 }} more...
                    </p>
                </div>
            </div>
            <div class="px-2 pb-2">
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.email">
                    <font-awesome-icon icon="envelope"></font-awesome-icon>
                </button>
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.facebook">
                    <font-awesome-icon v-bind:icon="['fab', 'facebook-square']"></font-awesome-icon>
                </button>
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.instagram">
                    <font-awesome-icon v-bind:icon="['fab', 'instagram']"></font-awesome-icon>
                </button>
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.twitter">
                    <font-awesome-icon v-bind:icon="['fab', 'twitter']"></font-awesome-icon>
                </button>
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.whatsapp">
                    <font-awesome-icon v-bind:icon="['fab', 'whatsapp']"></font-awesome-icon>
                </button>
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.telegram">
                    <font-awesome-icon v-bind:icon="['fab', 'telegram']"></font-awesome-icon>
                </button>
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.signal">
                    <font-awesome-icon icon="comment"></font-awesome-icon>
                </button>
                <button class="btn btn-lg btn-outline-secondary border-0"
                        v-if="client.weChat">
                    <font-awesome-icon v-bind:icon="['fab', 'weixin']"></font-awesome-icon>
                </button>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "ClientCard",
    props: {
        client: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        isSelected () {
            return this.$route.params.clientId === this.client.id;
        },
        id () {
            return this.client?.id ?? "";
        },
        name () {
            return this.client?.name ?? "";
        },
        email () {
            return this.client?.email ?? "";
        },
        badges () {
            return this.client?.badges ?? [];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.client-card {
    transition:
        transform 250ms ease,
        box-shadow 250ms ease-in-out;

    &.is-selected {
        transform: scale(1.025);
    }
}
</style>
