<template>
    <section class="clients container-fluid h-100 p-2 py-lg-3 pr-lg-3 pl-lg-0">
        <div class="row h-100 m-0">
            <div class="col-lg-7 col-xl-8 h-100 overflow-auto pl-lg-3">
                <div class="position-sticky title d-flex pb-2 mb-2">
                    <div class="d-flex d-xl-none align-items-center mr-2">
                        <button-toggle-side-bar></button-toggle-side-bar>
                    </div>
                    <div class="flex-grow-1 d-flex flex-nowrap justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <h2 class="mr-2 mb-0">
                                <span class="badge badge-light border">
                                    {{ clients.length }}
                                </span>
                            </h2>
                            <h1 class="font-weight-bolder text-nowrap mb-0">
                                Clients
                            </h1>
                        </div>

                        <button class="btn btn-light text-nowrap border rounded-pill"
                                v-on:click="isModalClientOpen = true">
                            ➕ Create
                        </button>
                    </div>
                </div>

                <div class="position-sticky search-bar d-flex justify-content-between align-items-center mb-3">
                    <b-form-input class="rounded-pill bg-light"
                                  type="search"
                                  size="sm"
                                  placeholder="🔎 Search clients..."
                                  v-model.trim="keywords"
                                  v-on:input="search">
                    </b-form-input>

                    <!-- <b-pagination class="mb-0"
                                  size="sm"
                                  v-bind:total-rows="vendors.length"
                                  v-bind:per-page="perPage"
                                  v-model="currentPage">
                    </b-pagination> -->
                </div>

                <div class="client-list row no-gutters mb-3">
                    <div class="col-12 empty-notice text-center py-4"
                         v-if="clients.length === 0">
                        <h1 class="display-3 mb-3">🈳</h1>
                        <div class="d-flex justify-content-center align-items-center flex-wrap">
                            <h5 class="font-weight-normal text-nowrap mb-0">
                                No client in store.
                            </h5>
                            <button class="btn btn-lg btn-link text-nowrap py-0 pr-1"
                                    v-on:click="isModalClientOpen = true">
                                Create one now?
                            </button>
                        </div>

                    </div>
                    <client-card class="col-md-6 col-lg-12 col-xl-6 p-2"
                                 v-for="client in clients"
                                 v-bind:key="client.id"
                                 v-bind:client="client">
                    </client-card>
                </div>
            </div>

            <transition name="slide-in">
                <router-view class="col-lg-5 col-xl-4 px-2 p-lg-0 h-100"></router-view>
            </transition>
        </div>

        <modal-client v-model="isModalClientOpen"></modal-client>
    </section>
</template>

<script>
import {
    BFormInput,
} from "bootstrap-vue";
import ClientCard from "../components/Clients/ClientCard";
import ButtonToggleSideBar from "../components/layout/ButtonToggleSideBar";
import ModalClient from "../components/Clients/ModalClient";

export default {
    name: "Clients",
    components: {
        ModalClient,
        ButtonToggleSideBar,
        ClientCard,
        BFormInput,
    },
    metaInfo: {
        title: "Clients",
    },
    data () {
        return {
            isModalClientOpen: false,

            keywords: "",
        };
    },
    computed: {
        clients () {
            return this.$store.getters["data/clients"];
        },
    },
    methods: {
        search () {

        },
    },
}
</script>

<style lang="scss" scoped>
.client-list {
    margin-left: -8.5px;
    margin-right: -8.5px;
}
</style>
